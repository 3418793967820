import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Home from "./../screens/Home";
import NotFound from "./../screens/NotFound";


const Routes = () => {
    return (
        <Switch>
            <Route
                path="/"
                exact
                component={Home}
            />
            <Route>
                <NotFound />
            </Route>
            <Redirect
                to="/"
            />
        </Switch>
    )
}
export default Routes;