
const  theme = () => ({
    root: {
        display: 'flex',
        width: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        '& h2': {
            fontSize: '3em',
            color: 'grey',
            zIndex: 2,
            textAlign: 'center',
            textShadow: '1px 1px 2px #000000',
        }
    }
})

export default theme;