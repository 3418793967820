import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Fade from 'react-reveal/Fade';
import styles from './styles';
import {
    withStyles,
    Container,
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    Paper
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import WorkdocIcon from '@material-ui/icons/InsertDriveFile';
import loadingImage from './../../assets/images/loader.gif';
import * as apiService from '../../utils/api';





const Home = ({
    classes
}) => {

    const [loading, setLoading] = useState(false)
    const [querySearch, setQuerySearch] = useState('')
    const [resultList, setResultList] = useState([])



    const onSearchHandler = async () => {
        setLoading(true)
        const loginPostData = { email: "cycuser@cycloid.pt", password: "teste123" }
        try {
            await apiService.login(loginPostData)
            const resp = await apiService.listData(querySearch)
            const list = resp.data.listEcmDatas.items;
            setResultList(list)
            setLoading(false)
        } catch (erro) {
            setLoading(false)
        }
    }

    const onListHandler = async () => {
        setLoading(true)
        const loginPostData = { email: "cycuser@cycloid.pt", password: "teste123" }
        try {
            await apiService.login(loginPostData)
            const resp = await apiService.listData('')
            const list = resp.data.listEcmDatas.items;
            setResultList(list)
            setLoading(false)
        } catch (erro) {
            setLoading(false)
        }
    }

    

    const onDownloadHandler = async (idx) => {
        try {
            const filename = resultList[idx].filename
            let url = await apiService.generatePresignedUrls(filename)
            window.open(url, '_blank');
        } catch (error) {}
    }

    const onWorkdocHandler = async (idx) => {
        try {
            window.open('https://cycdemos.awsapps.com/workdocs/index.html#/folder/707947f4e3576b4fea7febdc78053a482043441912959ca95d1eb74b8122b4fd', '_blank');
        } catch (error) { }
    }

    const renderList = () => {
        return resultList.map((item, idx) => {
            const index = item.filename.lastIndexOf('/')
            const filename = item.filename.substring(index+1)
            return (
                <Fade key={idx}  duration={100} delay={100 * idx}>
                    <div className={classes.resultRow}>
                        <p>{filename}</p>
                        <DownloadIcon onClick={() => onDownloadHandler(idx)}/>
                        <WorkdocIcon onClick={() => onWorkdocHandler()}/>
                    </div>
                </Fade  >
            )
        })
    }

    return (
        <div className={classes.root}>
            <Container  style={{ alignItems: 'center', height: '100%', zIndex: 2, maxWidth:'1380px'}} >
                <Grid container style={{ height: '100%' }} spacing={5} >
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box style={{ height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography variant="h5" style={{ textShadow: '1px 1px #23366f'}}>
                                Search for documents in Zurich Database
                            </Typography>
                            <div className={classes.searchBox}>
                                <TextField
                                    onChange={event => setQuerySearch(event.target.value)}
                                    style={{ width: '100%' }}
                                    id="input-text-search"
                                    variant="filled"
                                />
                                <Button
                                    variant="contained"
                                    className={classes.searchButton}
                                    startIcon={<SearchIcon />}
                                    onClick={onSearchHandler}
                                >
                                    Search
                                </Button>
                            </div>
                            <Button
                                variant="contained"
                                size="large"
                                className={classes.listButton}
                                onClick={onListHandler}
                            >
                                List all
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className={classes.boxList}>
                            {loading ? <img src={loadingImage} alt="" height={80} width={80} /> :
                                <div className={classes.boxListInner}>
                                    <Paper elevation={4} className={classes.cardResul} >
                                        {renderList()}
                                    </Paper>
                                </div>
                            }  
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <div className={classes.overlay}></div>
        </div>
    )
}

Home.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Home)
