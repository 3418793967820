
const MuiButton =  {
    label: {
        textTransform: 'none',
    },  
    containedPrimary: {
        backgroundColor: '#cc3024',
        '&:hover': {
            backgroundColor: '#cc3024'
        }
    },
    containedSecondary: {
        backgroundColor: 'green',
    }
};

export default MuiButton;
