import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import Header from './../Header';

const Main = ({
  children,
  classes
}) => {



  return (
    <div className={classes.root}>
        <div className={classes.container}>
            <Header />
            <main className={clsx({[classes.main]: true})} >
                  {children}
            </main>
          </div>
        </div>
  )
}

Main.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.any
}

export default compose(
  withStyles(styles)
)(Main)



