
const MuiAppBar =  {
    colorPrimary: {
        minHeight: '66px',
        backgroundColor: '#FFF',
        color: '#000'
    }
};

export default MuiAppBar;
