import { Storage, Auth, API, graphqlOperation } from "aws-amplify";
import { listEcmDatas } from "../../graphql/queries";



/************************************************** AUTH *****************************************************/
export const login = async (data) =>
    await Auth.signIn(data.email, data.password)

export const getCurrentSession = async (data) =>
    await Auth.currentSession()
    

export const listData = async (query) => 
  await API.graphql(graphqlOperation(listEcmDatas, {
        filter: {
            text: {
                contains: query
            }
        }
  }));
    
export const generatePresignedUrls = async (filename) => 
    await Storage.get(filename)