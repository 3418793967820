
const  classes = theme => ({
    root: {
        color: '#000'
    },
    title: {
        flexGrow: 1,
        fontWeight: 200,
        color: '#000'
    }
})

export default classes;