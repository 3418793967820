import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import {
    withStyles
} from '@material-ui/core';


const NotFound = ({
    classes
}) => {

    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <h2>{t('Not found')}</h2>
        </div>
    )
}

NotFound.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(NotFound)
