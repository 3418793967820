const classes =  theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        flexDirection: 'column'
    },
    main: {
        display: 'flex',
        overflowY: 'auto',
        flex: '1 1 auto',
        backgroundColor: '#fff',
    },
    mainPadding: {
        padding: '20px'
    }
})

export default classes;