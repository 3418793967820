/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6cbc02e2-6380-41fb-bc38-d7109f951495",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_joB9u86pl",
    "aws_user_pools_web_client_id": "3g2i0v8o033i6548qc9u5g8jub",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://asfxyaiovzcf7kzlencm2sslzu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "cyc-workflow-docs",
    "aws_user_files_s3_bucket_region": "eu-west-1",
};


export default awsmobile;
