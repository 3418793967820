import React, { useReducer, createContext } from "react";

import * as actionTypes from '../actions';

export const Context = createContext();


const initialState = {
   state: null
}


export const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.KEY: {
            return {
                ...state,
                state: null
            }
        }
        default:
            return state;
    }
}


const ContextProvider = (props) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        <Context.Provider value={value}>
            {props.children}
        </Context.Provider>
    )
}
export default ContextProvider;

