import React  from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    AppBar,
    Toolbar,
    Typography
} from '@material-ui/core';
import logo from './../../assets/icons/zurich-logo-blue.svg'



const Header = ({
    classes
}) => {

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h5" className={classes.title}>
                        Zurich Document Database Search
                    </Typography>
                    <div className={classes.logoBox}>
                        <img src={logo} alt="" />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    )
}

Header.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Header)
