
const MuiInput =  {
    underline: {
        '&:hover:not($disabled):before': {
            borderBottomColor: '#FFF',
        },
        '&:before': {
            borderBottomColor: '#FFFF'
        },
        '&:after': {
            borderBottomColor: '#FFFF'
        },
        '&.Mui-error': {
            '&:after': {
                borderBottomColor: '#FFFF'
            }
        }
    }
};

export default MuiInput;
