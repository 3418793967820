import MuiAppBar from './MuiAppBar';
import MuiButton from './MuiButton';
import MuiInputBase from './MuiInputBase';
import MuiInput from './MuiInput';


const overrides =  {
    MuiAppBar,
    MuiButton,
    MuiInput,
    MuiInputBase
}

export default overrides;