import backgroundImage from './../../assets/images/background1.jpeg';

const classes = (theme) => ({
    root: {
        width: '100%',
        backgroundSize: 'cover',
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#15909c',
        height: '100%',
        display: 'flex',
        position: 'relative',
        color: '#FFF',
        alignItems: 'center'
    },
    searchBox: {
        margin: '30px 0',
        display: 'flex'
    },
    searchButton: {
        marginLeft: '20px',
        backgroundColor: '#91bfe3',
        color: '#23366f',
        fontWeight: 800,
        padding: '10px 20px',
        '&:hover': {
            backgroundColor: '#91bfe3'
        },
        '& svg': {
            fontWeight: 800,
            fontSize: '24px'
        }
    },
    listButton: {
        width: '100%',
        backgroundColor: '#23366f',
        color: '#FFF',
        fontWeight: 800,
        padding: '10px 20px',
        '&:hover': {
            backgroundColor: '#23366f' 
        }
    },
    boxList: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '40px'
    },
    boxListInner: {
        width: '100%',
        maxHeight: '600px',
        overflowY: 'auto',
        paddingRight: '30px',
        [theme.breakpoints.down('lg')]: {
            maxHeight: '400px',
        }
    },
    resultRow: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        paddingTop: '30px',
        margin: '20px 0 30px',
        paddingBottom: '20px',
        borderBottom: '1px solid #ddd',
        '& p': {
            marginRight: 'auto',
        },
        '& svg': {
            cursor: 'pointer',
            margin: '0 15px',
            color:'#23366f'
        },
    },
     cardResul: {
        width: '100%',
        height: '100%', 
        borderRadius: '20px', 
        paddingRight: '30px',
         paddingLeft: '30px',
         color: '#000',
         backgroundColor: 'rgba(255, 255, 255, 0.95)'
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        top: '0',
        zIndex: 1
    }
})

export default classes;