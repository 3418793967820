
const MuiInputBase =  {
    input: {
        color: '#23366f',
        fontWeight: 800,
        backgroundColor: 'rgba(255, 255, 255, 0.6)'
    },
    root: {
        '&::after': {
            borderBottom: '3px solid #23366f !important'
        }
    }

};

export default MuiInputBase;
