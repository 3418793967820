import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import Main from './layouts/Main';
import Routes from './routes';
import theme from './theme';
import './assets/css/index.css';
import './i18n';
import ContextProvider from './context';



function App() {
  return (
      <ContextProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <Main>
                <Routes />
              </Main>
            </ThemeProvider>
          </BrowserRouter>
        </Suspense>
      </ContextProvider>
  );
}

export default App;
